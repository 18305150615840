.partnerCompanies_container
{
    padding-right: 0px;

}
.partnerCompanies_container .companies-logo-box
{
    display: flex;
    justify-content: center;
}


.partnerCompanies_container .swiper-wrapper
{
    align-items: end;
}