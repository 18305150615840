* {
  margin: 0; 
  padding: 0;
  scroll-behavior: smooth; 
  font-family: 'Rubik', sans-serif;
}
  
/* 
  Headings/Sub-Headings/Paragraphs/ Fonts Standard Spacing Fonts along with media queries,
*/

.font-roboto
{
  font-family: 'Roboto', sans-serif!important;
}

.standard-margin-space {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.standard-padding-space {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.body-heading , .body-heading-w, .hero-section-heading { 
  font-weight: 600;
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
}
.body-heading span, .body-heading-w span, .hero-section-heading span{ 
  font-weight: 600;
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
}

.body-heading { 
    color: #373737; 
    font-size: 40px; 
}
.body-heading-w { 
    color: #E4E4E4; 
    font-size: 40px; 

}

.sub-heading{
  font-size: 1rem;
  font-weight: 600;
  color: #A7A7A7;
}

.color-yellow
{
  color: #DB831C;
}

.hero-section-heading { font-size: 50px; color: white;  }
  
.body-paragraph { 
  font-weight: normal;
  font-size: 16px;
  color:#373737;
  font-family: 'Poppins', sans-serif;
}
  
.genral-btn { 
  /* padding: 0.5rem 1.5rem; */
  width: 219px;
  height: 47px;
  border-radius: 5px;
  background: rgb(172,100,5);
  background: linear-gradient(90deg, rgba(172,100,5,1) 0%, rgba(218,129,26,1) 100%, rgba(172,100,5,1) 100%);
  font-size: 16px;
  border: none;
  font-weight: 500;
  color: #ffffff;
  letter-spacing: 1px;
  transition: .3s;
}
.genral-btn:hover{
  background: rgb(172,100,5);
  background: linear-gradient(90deg, rgba(218,129,26,1) 0%, rgba(172,100,5,1) 100%,rgba(218,129,26,1) 100%);
  cursor: pointer;
}


@media (max-width: 1250px) {
  .body-heading-b,.body-heading-w  {
    font-size: 37px;
  }

  .hero-section-heading {
    font-size: 50px;
  }
}

@media (max-width: 460px) {
    .body-heading-b,.body-heading-w  {
    font-size: 30px;
  }
 
  .hero-section-heading  {
    font-size: 40px;
  }

  .body-paragraph {
    font-size: 15px;
  }
}

@media (max-width: 380px) {
.body-heading-b,.body-heading-w {
    font-size: 27px;
  }

  .hero-section-heading  {
    font-size: 32px;
  }

  .body-paragraph {
    font-size: 14px;
  }

  .sub-heading {  
    font-size: 12px; 
  } 
}




::-webkit-scrollbar {
  width: 12px;
}

::-moz-scrollbar {
  width: 12px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #DB831C;
  transition: .3s;
  border-radius: 3px;
}

::-moz-scrollbar-thumb {
  background: #DB831C;
  transition: .3s;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #DB831C;
}

::-moz-scrollbar-thumb:hover {
  background: #DB831C;
}

/* Track */
::-webkit-scrollbar-track {
  transition: .3s;
  background: #efc492;
}

::-moz-scrollbar-track {
  transition: .3s;
  background: #efc492;
}
/* Track on hover */
::-webkit-scrollbar-track:hover {
  transition: .3s;
  background: #efc492;
}

::-moz-scrollbar-track:hover {
  transition: .3s;
  background: #efc492;
}

/* Handle when dragging */
::-webkit-scrollbar-thumb:active {
  background: #DB831C;
}

::-moz-scrollbar-thumb:active {
  background: #DB831C;
}

/* Track when dragging */
::-webkit-scrollbar-track:active {
  transition: .3s;
  background: #efc492;
}

::-moz-scrollbar-track:active {
  transition: .3s;
  background: #efc492;
}
