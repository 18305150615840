.contact_section {
  padding: 120px 0px 120px 0px;
  width: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url("../../../../public/images/creatives/contact-form-bg.jpg");
  object-fit: contain;
  background-blend-mode: overlay;
  font-family: "Mulish", sans-serif;
  position: relative;
  height: auto;
  z-index: 10;
}

.contact_section .contact_form_form_container input
{
  border: none;
  border-bottom: 1px solid rgb(212, 212, 212);
  color: white;
}

.contact_section .contact-details .body-heading
{
  font-size: 30px;
}

.contact_section .contact-details .para_main
{
  font-size: 1.2rem;
}

.contact_section h4,
.contact_section h2,
.contact_section p {
  color: #ffffff;
  align-self: flex-start;
  text-align: start;
}
.contact_section .contact-details {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: start;
  flex-direction: column;
  margin-bottom: 50px;
}

.contact_section .form-btn {
  background-color: white;
  color: #DB831C;
  border: none;
  padding: 1rem 1.5rem;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  transition: .2s;
}
.contact_section .form-btn:hover {
  background-color: #DB831C;
  color: white;
}
.contact_section .contact-details h4 {
  font-size: 16px;
}

/* Styling for input fields */
.contact_section input[type="text"],
.contact_section input[type="tel"],
.contact_section input[type="email"],
.contact_section textarea {
  
  padding: 10px 10px;
  font-size: 16px;
  color: rgb(245, 245, 245);
  background: transparent;
  width: 100%;
}

/* Styling for input placeholders */
.contact_section input[type="text"]::placeholder,
.contact_section input[type="tel"]::placeholder,
.contact_section input[type="email"]::placeholder,
.contact_section textarea::placeholder {
  color: rgba(255, 255, 255, 0.594);
  font-size: small;
  font-family: "Poppins", sans-serif;
}

/* Remove default styling for focus */
.contact_section input:focus,
.contact_section textarea:focus,
.contact_section select:focus {
  outline: none;
}

.contact_section .button_col {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Optional additional styling for spacing */
.contact_section input,
.contact_section textarea,
.contact_section select {
  margin-bottom: 10px;
}
@media (max-width: 992px) {
  .contact_section {
    padding: 80px 0px 80px 0px;
  }
  .contact-details {
    padding-right: 0px;
  }
}
