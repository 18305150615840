.inadmissibility-call-container .text-container
{
    background-color: white;
    /* padding-top: 140px;
    padding-bottom: 140px; */

}
.inadmissibility-call-container .call-btn { 
    width: 219px;
    height: 47px;
    border-radius: 5px;
    background-color: rgb(172,100,5);
    font-size: 16px;
    border: none;
    font-weight: 500;
    color:white;
    letter-spacing: 1px;
    transition: .3s;
  }
  .inadmissibility-call-container .call-btn:hover{
    background-color: rgb(126, 74, 5);
    cursor: pointer;
  }

  .inadmissibility-call-container .body-paragraph
  {
    color:#373737;
  }