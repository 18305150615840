.discover-container
{
}
.discover-container .text-content h3
{
    font-size: 3rem;
}
.discover-container .text-content .body-paragraph
{
    width: 80%;
    margin: auto;
}

.discover-container .discover-card-box
{
    background-color: #FBFBFB;
    box-shadow: 0px 10px 26px -3px rgba(0, 0, 0, 0.116);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    justify-content: center;
    gap: 15px;
    padding:15px 10px;
    transition: .3s;
}
.discover-container .discover-card-box:hover
{
    background-color: #e1aa2a52;
    cursor: pointer;
}

.discover-container .discover-card-box .card-icon img{
    width: 60px;
    height: 60px;
}
.discover-container .discover-card-box .card-icon{
   
}

.discover-container .discover-card-box .card-title
{
    color :#DB831C;
    font-size: 1rem;
    font-weight: 600;
    margin-top: 10px;
}

.discover-container .discover-card-box .card-content
{
    color :#949494;
    font-size: .9rem;
}

.discover-container .discover-card-box .read-more-anchor
{
    text-decoration: none;
    font-size: .9rem;
    font-weight: 600;
    transition: .3s;

}


.discover-container .discover-card-box .read-more-anchor:hover{
    cursor: pointer;
    color: #E1A92A;
}
