.news_card .blog_list_logo
{
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
    margin-top: auto;
}
.news_card
{
    padding: 0;
    width: 100%;
    margin: auto;
    cursor: pointer;
    color: white;
    transition: .3s;
}

.news_card:hover
{
    padding:0 5px;
    border-radius: 10px;
}

.news_card .blog_list_logo_img
{
    width: 40px;
}

.news_card .blog_list_logo_title
{
    font-size: .8rem;
    font-weight: 600;
    color: #2D2D2D;
}

.news_card .blog_list_logo_date
{
    font-size: .7rem;
    color: #AFAFAF;
}

.news_card .sub_text
{
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    text-align: center;
    letter-spacing: 2.80px;
    color: #DB831C;
}
.news_card .card_text
{
    min-height: 180px;
}

.news_card .card_img
{
    width: 100%;
    height: 240px;
    border-radius: 10px;
}
.news_card .card_title
{
    font-family: "Poppins";
    font-size: 1.2rem; 
    font-weight:600;
    color:#2D2D2D;

}

@media(max-width:1000px)
{
    .news_card{
        width: 80%;
        margin: auto;
    }
}