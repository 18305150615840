.CardTestimonial-container
{
    
    background-repeat: no-repeat;
    background-size: cover;
    background-color: white;
    padding:70px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

}
.CardTestimonial-container .card-box
{
    padding: 5px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 10px;
    cursor: pointer;
}

.CardTestimonial-container .card-box .card-text-content
{
    width: 93%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: auto;
}

.CardTestimonial-container .card-box h5{
    color: black;
    font-size: 1rem;
    font-weight: 600;
}
.CardTestimonial-container .card-box p{
    font-size: .8rem;
}


.CardTestimonial-container .card-box img{
    width: 95%;
    height: 200px;
    margin:5% auto;
    margin-bottom: 10px;
    border-radius: 8px;
}

.CardTestimonial-container .testimonial-text-content
{
    margin-bottom: 60px;
    text-align: center;
}

.CardTestimonial-container .card-list
{
    margin: auto;
    width: 100%;
}

.CardTestimonial-container .btn-container
{
    margin-top: 32px;
}