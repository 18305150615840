.wp-centered-text-line-container
{

}

.wp-centered-text-line-container .body-heading-title
.wp-centered-text-container .text-content .body-heading-title
{
    font-size: 24px!important;
    color: #373737; 
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
}

.wp-centered-text-line-container .genral-btn
{
    width: max-content;
    padding: 10px;
}

.wp-centered-text-line-container .line-break
{
    width: 100%;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #939393;
}
.wp-centered-text-line-container .shortDivider
{
    width: 60%;
}

@media(max-width:660px)
{
    .wp-centered-text-line-container .shortDivider
    {
        width: 100%;
    }
}