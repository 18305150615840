.footer-container
{
    background-color: #E9E9E9;
    padding-top: 30px;
}

.footer-container .line-seprator
{
    border: 1px solid #949494;
    width: 100%;
    margin:20px auto;
}

/* Upper container */

.footer-container .upper-newsletter-container
{
    display: flex;
    justify-content: space-between;
    padding: 30px 0px;
}

.footer-container .upper-newsletter-container .text-container
{
    display: flex;
    align-items: center;
    gap: 14px;
}


.footer-container .upper-newsletter-container .newsletter-img{
    width: 110px;
    height: 80px;
}
.footer-container .upper-newsletter-container .newsletter-form
{
    display: flex;
    align-items: center;
}
.footer-container .upper-newsletter-container .newsletter-form input
{
    border: none;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding:15px 15px;
    width: 20em;
    background-color: #CFCFCF;
    transform: translateX(10px);
    color: black;
    z-index: 10;
}
.footer-container .upper-newsletter-container .newsletter-form input:focus
{
    outline: none;
}
.footer-container .upper-newsletter-container .newsletter-form button
{
    display: flex;
    z-index: 100;
    align-items: center;
    gap: 10px;
    border: none;
    border-radius: 12px;
    background-color: #D77F18;
    color: white;
    padding:12px 15px;
    font-weight: 700;
    font-size: 1.25rem;
    transition: .3s;
}

.footer-container .upper-newsletter-container .newsletter-form button img{
    width: 15px;
}

.footer-container .upper-newsletter-container .newsletter-form button:hover{
    cursor: pointer;
    background-color: #975810;
}

.footer-container .upper-newsletter-container .text-container .text-content p
{
    font-size: small;
    color: #CCCCCC;
}
.footer-container .upper-newsletter-container .text-container .text-content h3
{
    margin-bottom: 5px;
    font-family: "Poppins";
    font-weight: bold;
    font-size: 1.5rem;
    color: #2E2E2E;
}

.footer-container .upper-newsletter-container .text-container{
    width: 40%;
}

/* Middle link Container */

.footer-container .middle-links-container
{
    display: flex;
    padding: 30px 0;
    gap: 10px;
}

.footer-container .middle-links-container .left
{
    width: 30%;
    margin-right: auto;
}

.footer-container .middle-links-container .right
{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 10px 0;
    width: 60%;
    margin-left: auto;
}

.footer-container .middle-links-container .left p{
    font-size: .9rem;
    color: #949494;
}
.footer-container .middle-links-container .left img{
    cursor: pointer;
}



.footer-container .middle-links-container .right .right-upper
{
    display: flex;
    font-size:16px;
    padding-left: 0;
}

.footer-container .middle-links-container .right .right-upper li
{
    list-style: none;
    cursor: pointer;
    font-size: normal;
    color: #191919;
}
.footer-container .middle-links-container .right .right-upper li:hover{
    color: #D77F18;
}
.footer-container .middle-links-container .right .right-bottom
{
    gap: 30px;
    row-gap: 25px;
    padding-left: 0;
}
.footer-container .middle-links-container .right .right-bottom li
{
    display: flex;
    align-items: center;
    gap: 5px;
    
}

.footer-container .middle-links-container .right .right-bottom li a
{
    color:#2D2D2D ;
    text-decoration: none;
}
.footer-container .middle-links-container .right .right-bottom li
{
    list-style: none;
    cursor: pointer;
}
.footer-container .middle-links-container .right .right-bottom li a:hover{
    color:#626262;
}

.footer-container .middle-links-container .right .right-bottom .num-a{
    display: flex;
    align-items: center;
    gap: 5px;
    white-space: nowrap;
}

/* Lower Container */

.footer-container .lower-container
{
    background-color: white;
    padding:15px 0;
    color: #9B9B9B;
}
.footer-container .lower-container small a
{
    text-decoration: none;
    color: #9B9B9B;
}


.footer-container .middle-links-container .lower-link-list
{
    display: flex;
    margin-bottom: 0;
    gap: 10px;
    padding-left: 0;
}
.footer-container .middle-links-container .lower-link-list li
{
    list-style: none;
}
.footer-container .middle-links-container .lower-link-list li img
{
   width: 25px;
}
.footer-container .middle-links-container .lower-link-list li img:hover
{
   cursor: pointer;
}

@media(max-width : 1050px)
{
    .footer-container .upper-newsletter-container
    {
        flex-direction: column;
    }
    .footer-container .upper-newsletter-container .text-container
    {
        flex-direction: column;
        text-align: center;
    }
    .footer-container .upper-newsletter-container .text-container,.newsletter-form
    {
        margin: auto;
        margin-bottom: 15px;
    }
    .footer-container .middle-links-container .right .right-upper
    {
        padding: 0;
        font-size: x-small;
    }
    
}

@media (max-width: 730px) {
    
    .footer-container .middle-links-container
    {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .footer-container .upper-newsletter-container .text-container 
    {
        width: 100%;

    }
    .footer-container .upper-newsletter-container .newsletter-form
    {
        justify-content: center;
    }
    .footer-container .middle-links-container .right
    {
        width: 100%;
    }
    .footer-container .middle-links-container .left
    {
        width: 60%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin: auto;
        gap: 20px;
    }
    .footer-container .upper-newsletter-container .newsletter-form input
    {
        width: 80%;
        padding: 5px 10px;
    }
    .footer-container .upper-newsletter-container .newsletter-form button
    {
        padding: 10px;
    }
    .footer-container .middle-links-container .right .right-bottom
    {
        margin-top: 20px;
    }
    
    .footer-container .lower-container
    {
        padding-left: 10px;
        padding-right: 10px;
    }

    .footer-container .upper-newsletter-container .newsletter-form .subscribe-btn-text
    {
        display: none;
    }
}