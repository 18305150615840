.WorkParmitAdjecentList-container
{
}


.WorkParmitAdjecentList-container .text-content .heading
{
    color: #373737;
}
.WorkParmitAdjecentList-container .text-content .sub-title
{
    color: #A7A7A7;
    font-size: .8rem;
    font-weight: 500;
}
.WorkParmitAdjecentList-container .text-content .content
{
    color: #373737;
}

.WorkParmitAdjecentList-container .bio-container h5
{
    color: #A6A6A7;
    margin-bottom: 0;
}

.WorkParmitAdjecentList-container .bio-container .line-seprator
{
    height: 30px;
    border: 1.5px solid #A6A6A7;
}

.WorkParmitAdjecentList-container .stat-section .state-title
{
  font-size: 3rem;
  font-weight: 600;
}

.WorkParmitAdjecentList-container .bio-container .bio-data h5{
    font-family: "Poppins";
    color: #373737;
    width: fit-content;
}

.WorkParmitAdjecentList-container .list-bullet
{
    width: 15px;
    height: 15px;
}

.WorkParmitAdjecentList-container .list-para
{
    font-size: 16px;
    font-weight: normal;
    margin-left: 4px;
}

.WorkParmitAdjecentList-container .genral-btn
{
    padding: 0 20px;
    width: auto;
}

.WorkParmitAdjecentList-container .list-bullet
{
    width: 15px;
    height: 15px;
}

.WorkParmitAdjecentList-container .list-para
{
    font-size: 16px;
    color: #373737;
    margin-left: 8px;
}

@media(max-width : 992px)
{
    .WorkParmitAdjecentList-container .bio-container .bio-data h5{
        width: 100px;
    }
}