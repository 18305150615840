.inadmissible-text-container
{

}

.inadmissible-text-container .post-heading
{
    font-size: 1.4rem;
    font-weight: 600;
    color:#373737;
}