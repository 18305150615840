.application-main-container .application-container
{
    background-image: url("../../../public/images/creatives/bussiness-immigration-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 140px;
    padding-bottom: 140px;

}
.application-main-container .call-btn { 
    width: 219px;
    height: 47px;
    border-radius: 5px;
    background-color: white;
    font-size: 16px;
    border: none;
    font-weight: 500;
    color: rgb(172,100,5);
    letter-spacing: 1px;
    transition: .3s;
  }
  .application-main-container .call-btn:hover{
    background-color: rgb(172,100,5);
    color:white;
    cursor: pointer;
  }