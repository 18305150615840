.centered-text-line-container
{

}

.centered-text-line-container .genral-btn
{
    width: max-content;
    padding: 10px;
}

.centered-text-line-container .line-break
{
    width: 100%;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #939393;
}
.centered-text-line-container .shortDivider
{
    width: 60%;
}

@media(max-width:660px)
{
    .centered-text-line-container .shortDivider
    {
        width: 100%;
    }
}