.Testimonials-container
{
    background-color: white;
    display: flex;
    align-items: center;
    width: 80%;
    margin: auto;
    box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.097);
    justify-content: center;
    border-radius: 10px;
    position: relative;
    top: 90px;
    z-index: 100;
}

.Testimonials-container .testimonial-card
{
    cursor: pointer;
    width: 100%;

}
.Testimonials-container .testimonial-card .arrow-img
{
    width:45px;
}


.Testimonials-container .testimonial-card .testimonial-card-info
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}


.Testimonials-container .profile-info
{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.Testimonials-container .profile-info img{
    border-radius: 50%;
    width: 60px;
}

.Testimonials-container .profile-info .review-text
{
    /* font-size: .9rem; */
    margin-top: 30px;
    margin-bottom: 30px;

}
.Testimonials-container .profile-info .name
{
    font-size: 1.4rem;
    margin-top: 10px;
}
@media(max-width:800px)
{
    .Testimonials-container
    {
        width: 90%;
    }
}