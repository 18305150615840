.header {
    background: transparent;
    z-index: 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}


.header .active{
  border-bottom:1px solid #DB831C;
  color: #DB831C!important;
  height:65px;
  display:flex;
  align-items:center;
  justify-content:center;
}

.header .header-wrapper {
    width: 93.5%;
}

.header .nav-elements
{
  border-bottom: 2px solid rgba(255, 255, 255, 0.125);
}

.header .header-wrapper .navbar .navbar-container {
    width: 100%;
}
.header .header-wrapper .navbar .navbar-container .nav-logo{
  width: 140px;
  height: 55px;
  cursor: pointer;
    /* max-width:100px; */
}

.header .main-nav {
    list-style-type: none;
    margin: 0;
}

.header .nav-links,
.logo {
    text-decoration: none;
    color:  #ffffff;
    font-size: .9rem;
}

.header .nav-links:active,
.logo:active {
    color: #ffffff;
    font-weight: 500;
}

.header .nav-links:focus,
.logo:focus {
    color: #ffffff;
    font-weight: 500;
}

.header .main-nav li {
    text-align: center;
    /* color: #283285; */
}
.header .nav-list .nav-links
{
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    color: #E4E4E4;
    transition: .3s;
}
.header .nav-list .nav-links:hover
{
    color: #DB831C;
}

.header .extras-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}
.header .responsive-popup-menu
{
    z-index: 1;
    position: absolute;
    top: 80px;
    background-color: rgb(255, 255, 255);
    color: black;
}

.header .responsive-menu-btn img
{
    width: 25px;
    height: 25px;
}



.header .extras-btns .genral-btn
{
    font-size: .9rem;
    padding: 5px 15px;
    font-weight: 600;
    transition: .3s;
}

.header .extras-btns .genral-btn img {
    width: 17px;
    margin-right: 3px;
}

.header .resp-nav-list .nav-links 
{
    color: black;
}

.header .resp-nav-list li{
    list-style: none;
}
.header .resp-nav-list
{
    width: 100%;
}


/* Dropdown css */

.navigation-dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-trigger {
    cursor: pointer;
    color: white;
  }
  
  .dropdown-content {
    position: absolute;
    top: 110%;
    left: -15px;
    background-color: white;
    min-width: 80px;
    width: max-content;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 10px;
    padding: 10px 20px;
     display: flex;
    flex-direction: column;
    align-items: start;
    text-align: start;
    gap: 10px;
  }

  .dropdown-content .nav-links p,
  .dropdown-content li .nav-links
  {
    color: black!important;
  }
  .dropdown-content .nav-links p:hover,
  .dropdown-content li .nav-links:hover{
    color: #DB831C!important;
  }
  .dropdown-content-left {
    position: absolute;
    top: 0px;
    left: 187px;
    background-color: white;
    min-width: 80px;
    width: max-content;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 10px;
    padding: 10px 20px;
     display: flex;
    flex-direction: column;
    align-items: start;
    text-align: start;
    gap: 10px;
  }

  .dropdown-content-left .nav-links p{
    color: black;
  }
  .dropdown-content-left .nav-links p:hover{
    color: #DB831C;
  }
  
  
  /* Custom CSS Animation */
  .dropdown-content-enter {
    opacity: 0;
    transform: translateY(-10px);
  }
  
  .dropdown-content-enter-active {
    display: block;
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms, transform 300ms;
  }
  
  .dropdown-content-exit {
    opacity: 1;
    transform: translateY(0);
  }
  
  .dropdown-content-exit-active {
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 300ms, transform 300ms;
  }

  .header .arrow-icon
  {
    width: 10px;
    height: 10px;
    margin-left: 5px;
  }
  
  .header .rotate-icon {
    animation: rotate180 .3s forwards;
  }

  
  @keyframes rotate180 {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(180deg);
    }
  }
  
  /* Return to original position */
  .header .rotate-back {
    animation: rotateBack .1s forwards;
  }
  
  @keyframes rotateBack {
    from {
      transform: rotate(180deg);
    }
    to {
      transform: rotate(0deg);
    }
  }

  
  .header .nav-list .none-delay{
    display: none;
  }
  .header .nav-list .open{
    display: flex;
  }

  .header .header-social-link
  {
    list-style: none;
  }
  .header .header-social-link img
  {
    height: 28px;
    width: 28px;
  }

  @keyframes hide{
    from
    {
      display: flex;

    }
    to {
      display: none;
    } 
  }

  
@media(max-width : 1200px)
{
    .header .nav-list .nav-links
    {
        /* margin-right: 10px;
        margin-left: 10px; */
    }
    .header .arrow-icon{
      width: 10px;
      height: 10px;
      /* margin-left: 1px; */
    }
}