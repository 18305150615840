.ECardSection-container
{
}

.ECardSection-container .body-heading
{
    font-family: 'Poppins', sans-serif!important;

}

.ECardSection-container .bg-yellow-container
{
    background-color: #0a0a0a;
    border-radius: 12px;
    max-width: 1000px;
    background-image: url("../../../public/images/creatives/e-card-new-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0;
}

.ECardSection-container .overlay
{
    background-color: rgba(0, 0, 0, 0.246);
}

.ECardSection-container .main-container
{
    height: auto;
}

.ECardSection-container .social-icons-container li
{
    list-style: none;
}

.ECardSection-container .card-box
{
    background-color: transparent;
    padding: 10px 20px;
    cursor: pointer;
    transition: .3s;
}

.ECardSection-container .card-box:hover
{
    box-shadow: none;
    /* background-color: #d78a31; */
}

.ECardSection-container .card-anchor
{
    text-decoration: none;
}

.ECardSection-container .genral-btn
{
    background: white!important;
    color: #D77F18!important;
}
.ECardSection-container .genral-btn:hover
{
    background: #d78a31!important;
    color: white!important;
}

.ECardSection-container .main-img
{
    border-radius: 12px;
    background-color: rgba(0, 0, 0, 0.113);
    /* width: 80%; */
}

.ECardSection-container .ecard-icon
{
    width: 100px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 14px;
    
}





@media(max-width:992px)
{
    .ECardSection-container .container
    {
        width: 100%!important;
       
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .ECardSection-container .bg-yellow-container
    {
        border-radius: 0;
    }
    .ECardSection-container .main-container
    {
        min-height: 100vh;
    }
    .ECardSection-container .main-img
    {
        height: 150px;
    }
    .ECardSection-container .bg-yellow-container
    {
        width: 90%;
    }
    .ECardSection-container .body-heading
    {
        font-size: 25px;
    }
    .ECardSection-container .social-icons-container li a img
    {
        width: 30px;
    }
    .ECardSection-container .ecard-icon
    {
        width: 45px;
        height: 45px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 14px;
    }
    .ECardSection-container .card-box
    {
        padding: 0;
        margin-bottom: 5px;
    }

    .ECardSection-container .icon-label
    {
        font-size: 18px;
        font-weight: 500;
    }
}