.consultant-container
{
    background-image: url("../../../public/images/creatives/consultant-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 450px;
}

.consultant-container .text-container
{
}