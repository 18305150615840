.bg-img-container-main-container .application-container
{

    background-size: cover;
    background-repeat: no-repeat;
    /* background-position: center; */
    padding-top: 140px;
    padding-bottom: 140px;

}
.bg-img-container-main-container .call-btn { 
    width: max-content;
    height: 47px;
    border-radius: 5px;
    background-color: white;
    font-size: 16px;
    border: none;
    padding: 10px;
    font-weight: normal;
    color: rgb(172,100,5);
    letter-spacing: 1px;
    transition: .3s;
  }
  .bg-img-container-main-container .call-btn:hover{
    background-color: rgb(172,100,5);
    color:white;
    cursor: pointer;
  }