.inadmissibility-points-container
{
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: #F9F9F9;
    color: #373737;
}

.inadmissibility-points-container .inadmissibility-point
{
    width: 95%;
    margin: auto;
}

.inadmissibility-points-container .inadmissibility-points-list .title
{
    font-size: 1.1rem;
    color: #373737;
    font-weight: bold;
}

.inadmissibility-points-container .inadmissibility-points-list .line-seprator
{
    width: 100%;
    height: 0;
    border: 1px solid #bbbbbb;
}


.inadmissibility-points-container .body-paragraph
{
  color:#373737;
}