.common_hero_section {
  color: white;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background: rgb(18,18,23);
  width: 100%;
  height: auto;
  min-height: 100vh;
}

.common_hero_section .hero_content_wrapper {
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  min-height: 25em;
  height: 90vh;
}


.common_hero_section .hero_content_wrapper .body-paragraph
{
  width: 70%;
}

.common_hero_section .hero_content_wrapper .hero-section-heading,
.common_hero_section .hero_content_wrapper .body-paragraph {
  text-align: start;
}

.common_hero_section .hero_content_wrapper .hero-btn
{
  background-color: #203354;
  color: white;
  transition: .3s;
}
.common_hero_section .heading-color-change
{
  color: #203354;

}
.hero_content_wrapper .hero-section-heading
{
  max-width: 100%;
}
.hero_content_wrapper #heading-small
{
  font-size: 40px!important;
}

.common_hero_section .service-form
{
  background-color: transparent;
  border-radius: 10px;
}
.common_hero_section .service-form .service-from-btn
{
  width: 100%;
  padding: 15px 25px;
  border-radius: 5px;
  margin-top: 25px;
}
.common_hero_section .service-form .inp-field
{
  border: none;
  padding: 15px 20px;
  margin-top: 20px;
  background:#7070705e;
  border-radius: 9px;
  color: white;
  backdrop-filter: blur(8px);
  resize: none;
}
.common_hero_section .service-form .inp-field::placeholder
{
  color: #B5B5B5;
}
.common_hero_section .service-form .inp-field:focus
{
  outline: none;
}

.common_hero_section .side-image
{
  transform: translateY(120px);
}

.common_hero_section .stat-section .state-title
{
  color: white;
  font-size: 3rem;
  font-weight: 600;
}

.common_hero_section .chevron-icon
{
  width: 40px;
  height: 40px;
}

.common_hero_section .link-container img{
  width: 40px;
  height: 40px;
}

.common_hero_section .list-bullet
{
    width: 15px;
    height: 15px;
}

.common_hero_section .list-para
{
    font-size: 14px;
    margin-left: 4px;
}

.common_hero_section .post-para
{
  font-family: "Poppins";
  font-size: 38px;
  font-weight: 500;
  color: white;
}

.common_hero_section .sub-heading-text
{
  font-size: 26px;
  font-weight: normal;
}

@media(max-width : 660px)
{
  .common_hero_section .hero_content_wrapper .hero-section-heading
  {
    max-width: 100%;
  }
  .common_hero_section .hero_content_wrapper .body-paragraph
  {
    width: 90%;
  }
}