.blog-container
{
    background-color: white;
}
.blog-container .card-box
{
    color: black;
    display: flex;
    padding-bottom: 30px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    gap: 10px;
    box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.097);
    border-radius: 10px;
    height: 100%;
    cursor: pointer;
}

.blog-container .swiper-slide{
    height: auto;
}

.blog-container .card-box .card-text-content
{
    width: 93%;
    display: flex;
    flex-direction: column;
    gap: 7px;
    margin: auto;
}

.blog-container .card-box h5{
    color :#373737;
    font-size: 1.1rem;
    font-weight: 600;
    margin-top: 10px;
}
.blog-container .card-box h2{
    color :#373737;
    font-size: 1.1rem;
    font-weight: 600;
    margin-top: 10px;
}
.blog-container .card-box p{
    color :#949494;
    font-size: .78rem;
}


.blog-container .card-box a
{
    text-decoration: none;
    font-size: .8rem;
    transition: .3s;

}


.blog-container .card-box a:hover{
    cursor: pointer;
    color: #E1A92A;
}

.blog-container .card-box img{
    width: 100%;
    margin-bottom: 10px;
    border-radius: 8px;
}

.blog-container .card-box .btn-icon
{
    width: 12px;
    height: 12px;
    margin-bottom: 0;
    margin-left: 3px;
}

.blog-container .card-box .top-links
{
    display: flex;
    justify-content: start;
    gap: 15px;
}

.blog-container .card-box .top-links img{
    width: 15px;
}
.blog-container .card-box .top-links p{
    font-size: .8rem;

}

.blog-container .blog-text-content
{
}

.blog-container .blog-upper-content
{
}

.blog-container .blog-upper-content .blog-text-content
{
    
}
.blog-container .blog-upper-content .blog-btn
{
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 10px;
}
.blog-container .blog-upper-content .blog-btn button
{
    border-radius: 15px;
    border: none;
    padding: 10px 20px;
    color: #E1A92A;
}

.blog-container .card-list
{
    /* margin: auto;
    width: 90%; */
}

.blog-container .btn-arrow-icon
{
    width: 10px;
    height: 15px;
}

@media(max-width:768px)
{
    .blog-container .blog-upper-content .blog-btn
    {
        display: flex;
        align-items: center;
        justify-content: start;
    }
}