.blog-details-container
{
    color: #2D2D2D;
}

.blog-details-container .container
{
}

.blog-details-container .blog-thumbnail
{
    position: relative;
    top: -80px;
    color: #2D2D2D;
}

.blog-details-container .text-content .content-title
{
    color: #2D2D2D;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 1.8rem;
}
.blog-details-container .text-content .main-content-title
{
    font-weight: 600;
    font-size: 1.9rem;
}
.blog-details-container .text-content p
{
    line-height: 2rem;
}

.blog-details-container .social-icon-box{
    gap: 5px;
}

.blog-details-container .social-icon-box .social-icon{
    width: 40px;
    height: 40px;
}
.blog-details-container .line-seprator
{
    width: 100%;
    border: 1px solid #CCCCCC;
    margin: auto;
}

.blog-details-container .popular-card 
{
    cursor: pointer;
}
.blog-details-container .popular-card .card-title
{
    color: #2D2D2D;
    font-size: 1rem;
    font-weight: 600;
}
.blog-details-container .popular-card .card-date
{
    color: #CCCCCC;
}
.blog-details-container .popular-card .popular-blog-img
{
    width: 40%;
    height: 100%;
    max-height: 150px;
    border-radius: 10px;
}

.blog-details-container .popular-blog-container
{
    box-shadow: 0 3px 13px 0 RGBA(0,0,0,0.16 );
    border-radius: 22px;
}

.blog-details-container .blog-info .blog-info-field p
{
    color:#373737;
    font-size: 16px;
    font-weight: 300;
}