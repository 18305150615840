.faq-container
{
}   

.faq-container .text-content
{
    text-align: center;
}
.faq-container .text-content .user-img
{
    width: 150px;

}
.faq-container .text-content
{
    text-align: center;
    font-family: "Poppins";
    font-size: 16px;
    font-weight: normal;
    color: #373737;
}

.faq-container .dropdown-box
{
    cursor:pointer;
    width: 80%;
    border-radius: 20px;
    background-color: #F6F6F6;
    padding: 30px 30px;
}

.faq-container .line-seprator{
  width: 77%;
  border: 1px solid rgba(128, 128, 128, 0.199);
  margin: auto;

}

.faq-container .dropdown-box .box-head .title{
    font-weight: 600;
    font-size: 20px;
}

.faq-container .eye-icon
{
    width: 30px;
    height: 30px;
}

@keyframes bottomBoxOpen {
    from{
        display: none;
        transform: translateY(-3px);
    }
    to{
       display: block;
       transform: translateY(0px);
    }
}
@keyframes bottomBoxClose {
    from{
        display: block;
        transform: translateY(0px);
    }
    to{
        transform: translateY(-3px);
       display: none;
    }
}