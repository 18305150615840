.offers-citizenship-container
{

}

.offers-citizenship-container .list-point-container .list-para
{
    font-size: 16px;
    color: #373737;
    margin-left: 8px;
}
.offers-citizenship-container .list-point-container .list-bullet
{
    width: 17px;
    height: 14px;
}
.offers-citizenship-container .title
{
    font-size: 1.3rem;
    font-weight: 600;
    color: #373737;
}

.offers-citizenship-container .list-box
{
    height: 100%;
    box-shadow: 0 3px 18px 0 RGBA(0,0,0,0.16 );
    border-radius: 40px;
}

