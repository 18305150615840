.account-tab-container
{

}

.account-tab-container .left-tab-content 
{
    padding-top: 80px;
    cursor: pointer;
}
.account-tab-container .left-tab-content h5
{
    font-weight: 600;
}

.account-tab-container .left-tab-container
{
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.097);
}

.account-tab-container .right-pane-container
{
   padding: 10px;
}