.how-help-container
{

}
.how-help-container .line-seprator
{
    margin: auto;
    height: 0;
    width: 100%;
    border:1px solid #37373748;
}

.how-help-container .title
{
    font-size: 22px;
    font-weight: normal;
    color: #373737;
}